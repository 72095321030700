<script>
export default {
    name: "TableItem",
    props: {
        table: {
            type: Object,
            required: true,
        },
        isTableAvailable: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        clerk() {
            return this.$store.getters["transaction/clerk"];
        },
    },
};
</script>

<template>
    <a class="list-item p-4 flex justify-between font-bold">
        <div v-if="clerk && table.attributes.clerk_id" class="table-definer">
            <div class="my-table" v-if="table.attributes.clerk_id == clerk.id"></div>
        </div>

        <div>
            <span>{{ table.attributes.name }}</span>

            <span class="ml-1" v-if="table.attributes.name_temporary">
                ({{ table.attributes.name_temporary }})
            </span>

            <span class="ml-1 text-danger" v-if="!isTableAvailable">
                <svg id="Layer_1" x="0px" y="0px"
                     viewBox="0 0 330 330" style="enable-background:new 0 0 330 330;" xml:space="preserve"
                     stroke="currentColor" fill="currentColor" class="h-4 w-5 inline absolute"
                >
                    <g id="lock">
                        <path id="lock_top" d="M265,130h-15V84.999C250,38.13,211.869,0,165,0S80,38.13,80,84.999V130H65c-8.284,0-15,6.716-15,15v170
                            c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V145C280,136.716,273.284,130,265,130z M110,84.999
                            C110,54.673,134.673,30,165,30s55,24.673,55,54.999V130H110V84.999z M250,300H80V160h15h140h15V300z" />
                        <path id="lock_bottom" d="M196.856,198.144c-5.857-5.858-15.355-5.858-21.213,0L165,208.787l-10.644-10.643
                            c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L143.787,230l-10.643,10.644
                            c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394L165,251.213l10.644,10.644
                            c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L186.213,230l10.643-10.644
                            C202.715,213.499,202.715,204.001,196.856,198.144z" />
                    </g>
                </svg>
            </span>
        </div>

        <div class="text-right" v-if="table.attributes.total">
            {{ table.attributes.total.toFixed(2) }}
        </div>
    </a>
</template>
