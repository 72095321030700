<template>
    <navigation-mobile-pos title="Tafels" current_page="table" />

    <div class="flex flex-col stretcher-content" v-cloak>
        <loading-indicator v-if="!initialized" class="mt-64" />

        <div v-else>
            <div v-if="area?.attributes.pos_map_active && area?.attributes.mobile_pos_map_layout != 'no_map'"
                class="mobile-map-wrapper"
                ref="mobile-wrapper"
                :class="area?.attributes.mobile_pos_map_layout"
                :style="{ 'height: 100px;': area?.attributes.mobile_pos_map_layout == 'mobile_landscape' }"
            >
                <div v-if="area?.attributes.mobile_pos_map_layout == 'mobile_portrait'"
                    class="map border rounded-sm relative" :style="
                                'background:url(' + area?.attributes.pos_map_image + ');' +
                                'width: calc(760px * ' + terminal.map_factor + ');' +
                                'height: calc(440px * ' + terminal.map_factor + ');' +
                                'background-size: calc(760px * ' + terminal.map_factor + ') calc(440px * ' + terminal.map_factor + ') !important;'
                                ">
                    <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                        @click="selectTable(table.id)"
                        :class="{'opacity-50 cross': table.attributes.merged || !table.attributes.active_pos, 'rounded-full': table.attributes.map_shape == 'round', 'bg-red-500': table.relationships.lines.length > 0}"
                        class="bg-gray-300 table text-xs cursor-pointer absolute border text-center"
                        :style="'width:calc(' + table.attributes.map_width + ' * ' + terminal.map_factor + 'px); height:calc(' + table.attributes.map_height + ' * ' + terminal.map_factor + 'px); top: calc(' + table.attributes.map_positiontop + ' * ' + terminal.map_factor + 'px); left: calc(' + table.attributes.map_positionleft + ' * ' + terminal.map_factor + 'px)'">
                        <div class="truncate h-full flex justify-center items-center">
                            <div>
                                <div class="font-bold">{{ table.attributes.name }} <div v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</div></div>
                                <div class="italic">{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div v-else-if="area?.attributes.mobile_pos_map_layout == 'mobile_landscape'"
                    class="map border rounded-sm relative" :style="
                                'background:url(' + area.attributes.pos_map_image + ');' +
                                'width: ' + computedHeight + 'px;' +
                                'height: ' + computedWidth + 'px;' +
                                'background-size: ' + computedWidth + 'px ' + computedHeight + ' +px !important;'
                                ">
                    <div v-for="(table, index) in area.relationships.tables" v-bind:key="index"
                        @click="selectTable(table.id)"
                        :class="{'opacity-50 cross': table.attributes.merged || !table.attributes.active_pos, 'rounded-full': table.attributes.map_shape == 'round', 'bg-red-500': table.relationships.lines.length > 0}"
                        class="bg-gray-300 table text-xs cursor-pointer absolute border text-center"
                        :style="'width:calc(' + table.attributes.map_width + ' * ' + computedHeight/760 + 'px); height:calc(' + table.attributes.map_height + ' * ' + computedHeight/760 + 'px); top: calc(' + table.attributes.map_positiontop + ' * ' + terminal.map_factor + 'px); left: calc(' + table.attributes.map_positionleft + ' * ' + terminal.map_factor + 'px)'">
                        <div class="truncate h-full flex justify-center items-center">
                            <div>
                                <div class="font-bold">{{ table.attributes.name }} <div v-if="table.attributes.name_temporary">({{ table.attributes.name_temporary}})</div></div>
                                <div class="italic">{{ vueNumberFormat(table.attributes.total ? table.attributes.total : 0) }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else>
                <div class="px-4 mt-2 flex">
                    <input type="search" v-model="searchquery" placeholder="Zoeken" class="flex-grow rounded bg-gray-100 py-1 px-2 block w-full search focus:outline-none" />
                    <button class="button-toggle-clerk ml-2 button whitespace-no-wrap"
                            :class="{'button-filled': clerk_selected}"
                            @click="toggleClerk" v-if="clerk">
                        <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 50 50" x="0px" y="0px" fill="currentColor" stroke="currentColor" background="currentColor">
                            <path fill="currentColor" stroke="currentColor" d="M31,26a9,9,0,1,0-12,0,12.86,12.86,0,0,0-8.61,12.12,1.54,1.54,0,0,0,3.08,0,9.79,9.79,0,0,1,9.78-9.78h3.54a9.79,9.79,0,0,1,9.78,9.78,1.54,1.54,0,1,0,3.08,0A12.86,12.86,0,0,0,31,26ZM25,13.45a5.89,5.89,0,1,1-5.89,5.89A5.89,5.89,0,0,1,25,13.45Z">

                            </path>
                        </svg>
                    </button>
                </div>

                <div class="flex-grow overflow-y-auto px-2">
                    <div v-if="initialized">
                        <div
                            v-if="tables_filtered.length > 0"
                            class="mobile-full-screen-wrapper overflow-y-auto"
                        >
                            <component :is="TableItemContainerComp">
                                <component
                                    :is="TableItemComp"
                                    v-for="(table, index) in tables_filtered"
                                    :key="index"
                                    :table="table"
                                    :area="area"
                                    :is-table-available="isTableAvailable(table)"
                                    @click="selectTable(table.id)"
                                />
                            </component>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <SubtableModal
    v-if="!!area"
    ref="subtableModal"
    :area="area"
    :area_id="area_id"
    :show-parent-selection="true"
  />
</template>

<script>
import axios from 'axios'

// Mixins
import TerminalLayoutMixin from "@/mixins/terminal-layout.mixin";

// components
import SubtableModal from "@/components/mobilepos/SubtableModal.vue"
import NavigationMobilePos from '../../../components/NavigationMobilePos'
import LoadingIndicator from '../../../components/LoadingIndicator'
import ListViewTableItem from "@/components/mobilepos/list-view/TableItem.vue";
import ButtonViewTableItem from "@/components/mobilepos/button-view/TableItem.vue";
import ListViewTableItemContainer from "@/components/mobilepos/list-view/TableItemContainer.vue";
import ButtonViewTableItemContainer from "@/components/mobilepos/button-view/TableItemContainer.vue";

export default {
    name: 'mobilepos.tables',
    props: ['area_id'],

    mixins: [TerminalLayoutMixin],

    components: {
        SubtableModal,
        ButtonViewTableItem,
        ListViewTableItem,
        NavigationMobilePos,
        LoadingIndicator,
    },

    data () {
        return {
            initialized: false,
            tables: [],
            area: null,
            searchquery: '',
            clerk_selected: false,
            computedHeight: 0,
            computedWidth: 0,
            computedMapFactor: 1,
        }
    },

    async mounted() {
        this.tables = []

        await this.tablesGet()
        await this.areasGet();


        // when showing map in portrait mode, refactor the ratios to create a good fit on screen
        // all properties not used in portrait mode
        if (this.area?.attributes.mobile_pos_map_layout == 'mobile_landscape') {
            let mobileWrapper = this.$refs['mobile-wrapper'];
            if (mobileWrapper) {
                this.computedHeight = mobileWrapper.clientHeight - 16;
                this.computedMapFactor = 440/760;
                this.computedWidth = this.computedHeight * this.computedMapFactor - 16;
            }
        }
    },

    methods: {
        reload() {
            location.reload()
        },

        async tablesGet() {
            this.initialized = false
            this.tables = []
            await axios.get('/areas/' + this.area_id + '/tables').then(response => {
                this.tables = response.data.data;
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.initialized = true
            })
        },

        async areasGet() {
            this.area = null
            await axios.get('/areas').then(response => {
                this.area = response.data.data.filter(area => area.id == this.area_id)[0]
            }).catch(() => {
                this.$notify({ group: "notifications", type: 'error', title: "Fout opgetreden", text: "Oeps er ging iets fout.." }, 2000);
            }).finally(() => {
                this.initialized = true
            })
        },

        toggleClerk() {
            this.clerk_selected = !this.clerk_selected;
        },

        async selectTable(table_id) {
            // refresh tables
            await this.tablesGet();

            // find tables
            const table = this.tables.find(table => {
                return table.id == table_id;
            });

            if (this.isSubtableActive(table)) {
                this.$refs.subtableModal.open(table_id, table);
                return;
            }

            if (!this.isTableAvailable(table)) {
                this.$notify({ group: "notifications", type: 'error', title: "Tafel niet beschikbaar", text: "Tafel is reeds geclaimed" }, 2000);
                return;
            }
            this.$router.replace({ name: 'mobilepos.table', params: { area_id: this.area_id, table_id: table.id} });
        },

        isTableAvailable(table) {
             if (!table.attributes.active_clerk_restriction) {
                return true;
            }
            if (!table.attributes.clerk_id) {
                return true;
            }
            if (table.attributes.clerk_id == this.clerk.id) {
                return true;
            }
            return false;
        },

        isSubtableActive(table) {
            if (!(this.area?.attributes?.subtable_flag ?? false)) {
              return false
            }

            if (Number(table?.attributes?.subtable_count) === 0) {
              return false
            }

            return table?.attributes?.total > 0 || table?.attributes?.subtable_count > 0
        },
    },

	computed: {
        tables_filtered() {
            let tables = [];
            let self = this;
            let idxsToRemove = [];

            self.tables.forEach(function(table) {
                if (self.searchquery.length > 0) {

                    if (table.attributes.name.toUpperCase().includes(self.searchquery.toUpperCase())) {
                        tables.push(table);
                    }
                } else {
                    tables.push(table);
                }
            });

            if (this.clerk_selected) {
                tables.forEach(function(table, idx) {
                    if (parseInt(table.attributes.clerk_id) !== parseInt(self.clerk.id)) {
                        idxsToRemove.push(idx);
                    }
                });

                idxsToRemove.reverse().forEach(function(elem) {
                    tables.splice(elem, 1);
                });
            }

            return tables;
            // return [];
        },

		terminal() {
			return this.$store.getters['terminal/terminal'];
        },
		clerk() {
			return this.$store.getters['transaction/clerk'];
        },

        /* Components */
        TableItemComp() {
            if (this.isMobileOrderingButtons) {
                return ButtonViewTableItem;
            }

            return ListViewTableItem;
        },

        TableItemContainerComp() {
            if (this.isMobileOrderingButtons) {
                return ButtonViewTableItemContainer;
            }

            return ListViewTableItemContainer;
        },
    }
}
</script>
