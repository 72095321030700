<script>
import axios from "axios"
import { clearBodyLocks, lock, unlock } from "tua-body-scroll-lock"
import TableItem from "@/components/mobilepos/button-view/TableItem.vue"
import ModalClose from "@/components/ModalClose"

export default {
  name: "SubtableModal",
  props: ["area_id", "area", "showParentSelection"],
  emits: ["close"],

  components: {
    TableItem,
    ModalClose,
  },

  data() {
    return {
      isOpen: false,
      tableId: null,
      parentTable: null,
      subtables: [],
    }
  },

  mounted() {
    this.$watch("isOpen", (value) => {
      const el = this.$refs.modalRef

      if (value) {
        // lock(el)
      } else {
        // unlock(el)
        // clearBodyLocks()
      }
    })
  },

  methods: {
    open(tableId, table) {
      // clear previous subtables
      if (String(this.tableId) !== String(this.tableId)) {
        this.subtables = []
      }

      this.isOpen = true
      this.tableId = tableId
      this.parentTable = table

      this.fetchSubtables()
    },

    close() {
      this.isOpen = false
      this.tableId = null
      this.parentTable = null
      this.subtables = []
      this.$emit("close")
    },

    async newSubtable() {
      try {
        await axios.post(`/tables/${this.tableId}/child`)

        void this.fetchSubtables()
      } catch {
        this.$notify(
          {
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          },
          2000
        )
      }
    },

    async fetchSubtables() {
      try {
        const response = await axios.get(`/tables/${this.tableId}/child`)

        this.subtables = Array.isArray(response.data?.data) ? response.data.data : []
      } catch {
        this.$notify(
          {
            group: "notifications",
            type: "error",
            title: "Fout opgetreden",
            text: "Oeps er ging iets fout..",
          },
          2000
        )
      }
    },

    async selectTable(table_id) {
      let table

      // The parent table is already fetched at this point
      if (String(table_id) === String(this.tableId)) {
        table = this.parentTable
      } else {
        await this.fetchSubtables()
        table = this.subtables.find((table) => table.id === table_id)
      }

      if (!this.isTableAvailable(table)) {
        this.$notify(
          {
            type: "error",
            group: "notifications",
            title: "Tafel niet beschikbaar",
            text: "Tafel is reeds geclaimed",
          },
          2000
        )
        return
      }

      this.$router.push({
        name: "mobilepos.table",
        force: true,
        params: {
          area_id: this.area_id,
          table_id: table.id,
        },
      })

      this.close()
    },

    isTableAvailable(table) {
      if (!table.attributes.active_clerk_restriction) {
        return true
      }

      if (!table.attributes.clerk_id) {
        return true
      }

      return String(table.attributes.clerk_id) === String(this.clerk.id)
    },
  },

  computed: {
    clerk() {
      return this.$store.getters["transaction/clerk"]
    },
  },
}
</script>

<template>
  <teleport to="#mobile-modal">
    <div
      v-if="isOpen"
      class="fixed z-40 inset-0 overflow-hidden"
    >
      <div
        class="fixed inset-0 transition-opacity"
        @click="close()"
      >
        <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      <div
        id="modal"
        class="w-full h-full inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style="border-radius: 0"
      >
        <div class="modal-content flex flex-col justify-start items-start h-full overflow-hidden">
          <modal-close
            @close="close()"
            class="top-4 right-4 rounded"
          />

          <div
            ref="modalRef"
            class="w-full flex-shrink-0 flex flex-col items-start gap-2 mb-6 pb-4 border-b"
          >
            <h3
              id="modal-headline"
              class="modal-title leading-6 font-bold text-3xl mb-0"
            >
              Kies tafel:
            </h3>

            <p class="text-base font-bold">{{ parentTable?.attributes?.name ?? "-" }}</p>
          </div>

          <div class="w-full flex-1 flex flex-col justify-start items-start overflow-auto">
            <div class="w-full grid grid-cols-3 gap-2.5">
              <TableItem
                v-if="parentTable && showParentSelection"
                :area="area"
                :table="parentTable"
                :is-table-available="isTableAvailable(parentTable)"
                :hide-subtable-indicator="true"
                @click.prevent="selectTable(parentTable.id)"
              />

              <TableItem
                v-for="(table, index) in subtables"
                :key="index"
                :area="area"
                :table="table"
                :is-table-available="isTableAvailable(table)"
                @click.prevent="selectTable(table.id)"
              />

              <button
                class="list-item relative flex items-center justify-center rounded-lg overflow-hidden focus:outline-none"
                @click.prevent="newSubtable"
              >
                <span class="sr-only">Add</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>
