<script>
import { differenceInMinutes, parse } from "date-fns"

export default {
  name: "TableItem",
  props: {
    area: {
      type: [Object, null],
      required: true,
    },
    table: {
      type: Object,
      required: true,
    },
    isTableAvailable: {
      type: Boolean,
      required: true,
    },
    hideSubtableIndicator: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    clerk() {
      return this.$store.getters["transaction/clerk"]
    },

    updatedInMinutes() {
      if (this.table.attributes?.last_updated_minutes) {
        return Number(this.table.attributes.last_updated_minutes)
      }

      // Fallback calculation, timezones are not considered?
      const updatedAt = this.table.attributes?.updated_at ?? null
      if (!updatedAt) return 0

      const updatedDate = parse(updatedAt, "HH:mm:ss dd-MM-yyyy", new Date())

      return differenceInMinutes(new Date(), updatedDate)
    },

    inactiveLevel1() {
      if (!this.area) return null

      return this.area.attributes?.inactive_level1 || 15
    },

    inactiveLevel2() {
      if (!this.area) return null

      return this.area?.attributes?.inactive_level2 || 30
    },

    isSubtableActive() {
      if (!(this.area?.attributes?.subtable_flag ?? false)) {
        return false
      }

      return !this.table.attributes?.parent_id && this.table?.attributes?.subtable_count > 0
    },
  },
}
</script>

<template>
  <a class="list-item relative flex flex-col justify-start font-medium rounded-lg overflow-hidden">
    <div
      v-if="clerk && table.attributes.clerk_id"
      class="table-definer"
    >
      <div
        class="my-table"
        v-if="table.attributes.clerk_id == clerk.id"
      />
    </div>

    <button
      v-if="!hideSubtableIndicator && isSubtableActive"
      class="text-sm font-medium outline-none flex items-center justify-center gap-1 absolute right-2 top-1"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="lucide lucide-scroll-text w-3 h-3"
      >
        <path d="M15 12h-5" />
        <path d="M15 8h-5" />
        <path d="M19 17V5a2 2 0 0 0-2-2H4" />
        <path
          d="M8 21h12a2 2 0 0 0 2-2v-1a1 1 0 0 0-1-1H11a1 1 0 0 0-1 1v1a2 2 0 1 1-4 0V5a2 2 0 1 0-4 0v2a1 1 0 0 0 1 1h3"
        />
      </svg>

      <span>
        {{ table.attributes?.subtable_count >= 100 ? "99+" : table.attributes?.subtable_count }}
      </span>
    </button>

    <div class="pt-2 pb-3">
      <span>{{ table.attributes.name }}</span>

      <span
        class="ml-1"
        v-if="table.attributes.name_temporary"
      >
        ({{ table.attributes.name_temporary }})
      </span>

      <span
        class="ml-1 text-danger"
        v-if="!isTableAvailable"
      >
        <svg
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 330 330"
          style="enable-background: new 0 0 330 330"
          xml:space="preserve"
          stroke="currentColor"
          fill="currentColor"
          class="h-4 w-5 inline absolute"
        >
          <g id="lock">
            <path
              id="lock_top"
              d="M265,130h-15V84.999C250,38.13,211.869,0,165,0S80,38.13,80,84.999V130H65c-8.284,0-15,6.716-15,15v170
              c0,8.284,6.716,15,15,15h200c8.284,0,15-6.716,15-15V145C280,136.716,273.284,130,265,130z M110,84.999
              C110,54.673,134.673,30,165,30s55,24.673,55,54.999V130H110V84.999z M250,300H80V160h15h140h15V300z"
            />
            <path
              id="lock_bottom"
              d="M196.856,198.144c-5.857-5.858-15.355-5.858-21.213,0L165,208.787l-10.644-10.643
              c-5.857-5.858-15.355-5.858-21.213,0c-5.858,5.858-5.858,15.355,0,21.213L143.787,230l-10.643,10.644
              c-5.858,5.858-5.858,15.355,0,21.213c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394L165,251.213l10.644,10.644
              c2.929,2.929,6.768,4.394,10.606,4.394s7.678-1.464,10.606-4.394c5.858-5.858,5.858-15.355,0-21.213L186.213,230l10.643-10.644
              C202.715,213.499,202.715,204.001,196.856,198.144z"
            />
          </g>
        </svg>
      </span>
    </div>

    <div
      v-if="table.attributes.total"
      class="absolute inset-x-0 bottom-0 bg-sjefgreen-500 px-2 h-4 text-xs flex items-center justify-end"
      :class="{
        'indicator-yellow': inactiveLevel1 && updatedInMinutes >= inactiveLevel1,
        'indicator-red': inactiveLevel2 && updatedInMinutes >= inactiveLevel2,
      }"
    >
      {{ table.attributes.total.toFixed(2) }}
    </div>
  </a>
</template>
