<script>
export default {
  name: "TableItemContainer",
}
</script>

<template>
  <div class="grid grid-cols-3 gap-2.5">
    <slot />
  </div>
</template>
